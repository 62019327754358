



















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CustomDatePicker from '@/components/Shared/CustomDatePicker.vue';
import RentProducts from '@/components/Rent/RentProducts.vue';
import { GlobalPromotion, ProductType, Season } from '@/models/model';
import home from '@/store/home';
import order from '@/store/order';

@Component({
  components: {
    CustomDatePicker,
    RentProducts
  },
  metaInfo() {
    return {
      title: `${this.$t('navigation.rent' + ProductType[(this as RentProductType).productId])}`,
      htmlAttrs: {
        lang: this.$root.$i18n.locale
      },
      meta: [
        {
          name: 'description',
          content: `${this.$t('meta.rentProductTypeDescription', { productType: this.$t('productTypes.' + (this as RentProductType).productId) })}`
        },
        {
          name: 'og:title',
          content: `${this.$t('navigation.rent' + ProductType[(this as RentProductType).productId])}`
        },
        {
          name: 'og:description',
          content: `${this.$t('meta.rentProductTypeDescription', { productType: this.$t('productTypes.' + (this as RentProductType).productId) })}`
        },
        {
          name: 'twitter:title',
          content: `${this.$t('navigation.rent' + ProductType[(this as RentProductType).productId])}`
        },
        {
          name: 'twitter:description',
          content: `${this.$t('meta.rentProductTypeDescription', { productType: this.$t('productTypes.' + (this as RentProductType).productId) })}`
        }
      ]
    };
  }
})
export default class RentProductType extends Vue {

  private startDate: Date = ('' as any);
  private endDate: Date = ('' as any);
  private promotion: GlobalPromotion | undefined = ('' as any);

  @Prop()
  productId!: number;

  // TODO: Save start & end date in localStorage
  public ready = false;

  @Watch('startDate')
  startDateChanged(): void {
    if (this.endDate < this.startDate) {
      this.endDate = this.addDays(this.startDate, 6);
    }
    this.checkPromotion();
  }

  @Watch('endDate')
  endDateChanged(): void {
    this.checkPromotion();
  }

  @Watch('productId')
  productIdChanged(): void {
    this.init();
  }

  mounted(): void {
    this.startDate = this.nextSunday();
    this.endDate = this.addDays(this.startDate, 6);

    if (this.outOfSeason && this.seasons.length > 0) {
      const lastSeason = this.seasons.sort((a, b) => a.startDate < b.endDate ? 1 : a.startDate > b.endDate ? -1 : 0)[0];
      if (lastSeason.startDate > new Date()) {
        this.startDate = this.addDays(lastSeason.startDate, 1);
        this.endDate = this.addDays(this.startDate, 6);
      }
    }

    this.init();
  }

  public init(): void {
    this.ready = false;
    this.$nextTick().then(() => {
      this.checkPromotion();
      this.ready = true;
    });
  }

  checkPromotion(): void {
    if (order.currentOrder && order.currentOrder.promotionCodeId) {
      return;
    }
    const promotions = this.promotions.filter(p => p.startDate <= this.startDate && p.endDate >= this.endDate);
    if (promotions.length) {
      let promotion = promotions[0];
      if (promotions.length > 1) {
        const maxPromo = Math.max(...promotions.map(p => p.promotion));
        promotion = promotions.find(p => p.promotion === maxPromo)!;
      }

      if (order.currentOrder && order.currentOrder.globalPromotionId && order.currentOrder.globalPromotionId !== promotion.id) {
        this.promotion = undefined;
        return;
      }
      this.promotion = promotion;
    } else {
      this.promotion = undefined;
    }
  }

  nextSunday(): Date {
    const d = new Date();
    let now = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    now.setDate(now.getDate() + (7+(7-now.getDay())) % 7);

    if (now.getDate() === d.getDate()) {
      now = this.addDays(now, 1);
    }

    return now;
  }

  addDays(date: Date, days: number): Date {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  datediff(first: Date, second: Date): number {
      return Math.round((second.getTime() - first.getTime())/(1000*60*60*24));
  }

  get promotions(): GlobalPromotion[] {
    return home.promotions;
  }

  get langSuffix(): string {
      return this.$root.$i18n.locale === 'en' ? 'En' : '';
  }

  get maxRentDays(): number {
    return this.productId == ProductType.Monoskis || this.productId == ProductType.ChildrenSkis || this.productId == ProductType.ChildrenSnowboards ? 7 : 13;
  }

  get invalidDates(): boolean {
    const today = new Date();
    return !this.startDate || !this.endDate || today > this.startDate || this.endDate < this.startDate || this.datediff(this.startDate, this.endDate) > this.maxRentDays;
  }

  get singleDay(): boolean {
    return this.startDate && this.endDate && (this.startDate.getMonth() === this.endDate.getMonth() && this.startDate.getDate() === this.endDate.getDate());
  }

  get outOfSeason(): boolean {
    return this.startDate && this.endDate && this.seasons.filter(s => s.startDate <= this.startDate && s.endDate >= this.endDate).length === 0;
  }

  get closed(): boolean {
    const today = new Date();
    return this.seasons.filter(s => (s.startDate <= today && s.endDate >= today) || s.startDate >= today).length === 0;
  }

  get seasons(): Season[] {
    return home.seasons.filter(s => s.active);
  }
}
