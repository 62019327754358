

















































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import home from '@/store/home';
import order from '@/store/order';
import { Accessory, GlobalPromotion, OrderProduct, Package, Picture, Product, ProductType, Season } from '@/models/model';
import Api from '@/api/api';
import PriceCalculator from '@/helper/priceCalculator';
import toaster, { ToastImpl } from '@/store/toaster';

@Component
export default class RentProducts extends Vue {

  @Prop()
  startDate!: Date;

  @Prop()
  endDate!: Date;

  @Prop()
  productType!: ProductType;

  @Prop()
  promotion!: GlobalPromotion;

  @Prop()
  maxRentDays!: number;

  private currentSelection: Product | undefined = ({} as any);
  private currentOrderProduct: OrderProduct | undefined = ({} as any);
  private validateDatesProduct: Product | undefined = ({} as any);

  mounted(): void {
    if (!this.products || this.products.length === 0) {
      home.dispatchLoadDatas().then(() => this.initSelection());
    } else {
      this.initSelection();
    }
  }

  initSelection(): void {
    this.filtredProducts.forEach(p => {
      const availablePackages = p.packages ? p.packages.filter(pa => !this.isUnavailablePackage(p, pa)) : [];
      p.selectedPackage = availablePackages.length > 0 ? p.packages.indexOf(availablePackages[0]) : -1;
    });
    this.accessories.forEach(a => {
      a.selected = false;
      a.selectedSize = 0;
    });
  }

  getPictureUrl(picture: Picture): string {
      return Api.apiUrl + 'v1/document/picture/' + picture.hash + '?fileName=' + picture.name;
  }

  getPrice(product: Product, applyPromotion = true): number {
    const days = PriceCalculator.datediff(this.startDate, this.endDate);
    const rentPrice = product.rentPrices.find(p => p.days == days);
    return rentPrice ? PriceCalculator.round(rentPrice.price * (this.promotionApplying && applyPromotion ? this.getPromotionPct() : 1)) : 0;
  }

  getAccessoryPrice(accessory: Accessory): number {
    const days = PriceCalculator.datediff(this.startDate, this.endDate);
    if (this.currentOrderProduct && this.currentOrderProduct!.age && accessory.freeUnderAge && this.currentOrderProduct!.age < accessory.freeUnderAge) {
      return 0;
    }
    const rentPrice = accessory.rentPrices.find(p => p.days == days);
    return rentPrice ? PriceCalculator.round(rentPrice.price) : 0;
  }

  getPromotionPct(): number {
    if (this.promotion) {
      return 1 - (this.promotion.promotion / 100);
    }
    return 1;
  }

  cancelCartModal(): void {
    this.currentSelection = undefined;
    this.currentOrderProduct = undefined;
  }

  cancelShowCartModal(): void {
    this.validateDatesProduct = ({} as any);
  }

  showCartModal(product: Product, confirm = false): void {
    if (this.isSelectionUnavailable(product)) {
      return;
    }

    if (!confirm) {
      this.validateDatesProduct = product;
      var confirmDatesModal = (window as any).bootstrap.Modal.getOrCreateInstance(this.$refs.confirmDatesModal);
      confirmDatesModal.show();
      return;
    }

    this.currentSelection = product;
    this.currentOrderProduct = ({
      productId: product.id,
      startDate: this.startDate,
      endDate: this.endDate,
      package: product.selectedPackage !== -1 ? product.packages[product.selectedPackage!] : undefined,
      product: {...product},
      gender: 'M',
      firstName: '',
      lastName: '',
      age: '',
      weight: '',
      height: '',
      bootSize: '',
      productAccessories: []
    } as any);
    this.accessories.forEach(a => {
      a.selected = false;
      a.selectedSize = 0;
    });

    var modal = (window as any).bootstrap.Modal.getOrCreateInstance(this.$refs.rentModal);
    modal.show();
  }

  addToCart(): void {
    const accessories = this.accessories.filter(a => a.selected);
    if (accessories.length) {
      this.currentOrderProduct!.productAccessories = accessories.map(a => { return { accessoryId: a.id, quantity: 1, size: a.hasSize ? a.selectedSize : 0 }; });
    }
    order.commitSetAddProduct(this.currentOrderProduct!);
    if (this.promotion && !order.currentOrder!.globalPromotion) {
      order.currentOrder!.globalPromotion = this.promotion;
      order.currentOrder!.globalPromotionId = this.promotion.id;
      order.commitSetCurrentOrder(order.currentOrder);
    }
    toaster.dispatchToast(new ToastImpl('success', `${this.$t('common.cart')}`, `${this.$t('common.cartAdded')}`));
  }

  public isUnavailable(product: Product, inPackage: boolean): boolean {
    return this.startDate && this.endDate &&
      product.unavailabilityDates &&
      (inPackage && product.unavailabilityProductOnly ? false : (
        product.unavailabilityDates.filter(d =>
          (d.startDate <= this.startDate && d.endDate >= this.endDate) ||
          (d.startDate >= this.startDate && d.startDate <= this.endDate) ||
          (d.endDate >= this.startDate && d.endDate <= this.endDate)).length > 0));
  }

  public isUnavailablePackage(product: Product, pack: Package): boolean {
    const products = this.products.filter(p => pack.additionalProductIds.indexOf(p.id) !== -1);
    products.push(product);
    return products.filter(p => this.isUnavailable(p, p != product)).length > 0;
  }

  public isSelectionUnavailable(product: Product): boolean {
    return this.isUnavailable(product, false) || (product.selectedPackage !== -1 && this.isUnavailablePackage(product, product.packages[product.selectedPackage!]));
  }

  get canAddToCart(): boolean {
    const selectedAccessories = this.accessories.filter(a => a.selected);
    return !!this.currentOrderProduct && !!this.currentOrderProduct.firstName && !!this.currentOrderProduct.lastName &&
      !!this.currentOrderProduct.gender && this.currentOrderProduct.age > 0 && this.currentOrderProduct.weight > 0 &&
      this.currentOrderProduct.height > 0 && this.currentOrderProduct.bootSize > 0 &&
      (!selectedAccessories.length || (selectedAccessories.filter(a => !a.hasSize || a.selectedSize).length === selectedAccessories.length));
  }

  get promotionApplying(): boolean {
    const days = PriceCalculator.datediff(this.startDate, this.endDate);
    return this.promotion && (this.promotion.minRentDays === 0 || days >= this.promotion.minRentDays);
  }

  get invalidDates(): boolean {
    const today = new Date();
    return !this.startDate || !this.endDate || today > this.startDate || this.endDate < this.startDate || PriceCalculator.datediff(this.startDate, this.endDate) > this.maxRentDays;
  }

  get outOfSeason(): boolean {
    return this.startDate && this.endDate && this.seasons.filter(s => s.startDate <= this.startDate && s.endDate >= this.endDate).length === 0;
  }

  get closed(): boolean {
    const today = new Date();
    return this.seasons.filter(s => (s.startDate <= today && s.endDate >= today) || s.startDate >= today).length === 0;
  }

  get singleDay(): boolean {
    return this.startDate && this.endDate && (this.startDate.getMonth() === this.endDate.getMonth() && this.startDate.getDate() === this.endDate.getDate());
  }

  get products(): Product[] {
    return home.products;
  }

  get filtredProducts(): Product[] {
    return this.products.filter(p => p.type == this.productType).sort((a, b) => a.rentPrices.find(p => p.days === 1)!.price > b.rentPrices.find(p => p.days === 1)!.price ? 1 : a.rentPrices.find(p => p.days === 1)!.price < b.rentPrices.find(p => p.days === 1)!.price ? -1 : 0);
  }

  get accessories(): Accessory[] {
    return home.accessories;
  }

  get seasons(): Season[] {
    return home.seasons.filter(s => s.active);
  }

  get langSuffix(): string {
    return this.$root.$i18n.locale === 'en' ? 'En' : '';
  }

}
